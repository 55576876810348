import * as React from "react";
import {
  Row,
  Col,
  Divider,
  Button,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import { EnvironmentFilled, PhoneFilled, MailFilled } from "@ant-design/icons";
import HeaderComp from "../components/header";
import "../components/layout.css";
import TextArea from "antd/lib/input/TextArea";
import FooterComp from "../components/footer";
import axios from "axios";

const { Option } = Select;
const ContactPage = () => {
  const [form] = Form.useForm();
  const handleSubmit = (value) => {
    console.log("values", value);
    let contactUrl =
      "https://2tz6e74yv6.execute-api.us-east-2.amazonaws.com/contact";
    const getFeildsValues = {
      name: `${value.name}`,
      email: `${value.email}`,
      phone: `${value.phone}`,
      phone_country_code: `${value.prefix}`,
      webinar_id: "65",
      city: `${value.city}`,
      desc: `${value.moreInfo}`,
      page_referrer: "https://google.com/",
    };
    axios({
      url: contactUrl,
      method: "POST",
      async: true,
      crossDomain: true,
      data: getFeildsValues,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      {
        notification["success"]({
          message: JSON.parse(response.data.data.processPipeline)
            .ResponseMessage,
        });
      }
      form.resetFields();
    });
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="91">+91</Option>
      </Select>
    </Form.Item>
  );

  return (
    <>
      <HeaderComp />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1920,
          // padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <div className="contactBanner">
          <Row className="enterpriseTitleRow">
            <Col span={24}>
              <h1 className="enterpriseTitle">Contact Us</h1>
            </Col>
          </Row>
        </div>
        <div style={{ padding: "5% 5% 0", backgroundColor: "#f2f3f3" }}>
          <Row>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <h1 className="contactTitle">Get in touch</h1>
              <p className="contactPara">
                We appreciate your interest in CW Solutions. Please share your
                contact details by filling the form with your requirement and we
                will get back to you at the earliest.
              </p>
              <Divider />
              <p className="contactInfo">VISIT US</p>
              <p>
                <EnvironmentFilled className="contactInfoIcon" />{" "}
                <span className="contactInfoDetail">
                  India | UK | Singapore | UAE
                </span>
              </p>
              <p className="contactInfo">PHONE</p>
              <p>
                <PhoneFilled className="contactInfoIcon" />{" "}
                <span className="contactInfoDetail">+91 8806612345</span>
              </p>
              <p className="contactInfo">EMAIL</p>
              <p>
                <MailFilled className="contactInfoIcon" />{" "}
                <span className="contactInfoDetail">sales@cw.solutions</span>
              </p>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 16 }}
              xl={{ span: 16 }}
              xxl={{ span: 16 }}
            >
              <div className="contactForm">
                <Form
                  form={form}
                  name="contactForm"
                  onFinish={handleSubmit}
                  initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    city: "",
                    moreInfo: "",
                    prefix: "91",
                  }}
                >
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                      xxl={{ span: 12 }}
                    >
                      <label className="contactFieldLabel">Name*</label>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your firstname",
                          },
                        ]}
                      >
                        <Input className="contactFieldInput" size="large" />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                      xxl={{ span: 12 }}
                      className="contactFieldCol"
                    >
                      <label className="contactFieldLabel">
                        Email Address*
                      </label>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email address",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          className="contactFieldInput"
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                      xxl={{ span: 12 }}
                    >
                      <label className="contactFieldLabel">Phone Number</label>
                      <Form.Item name="phone">
                        <Input
                          type="number"
                          className="contactFieldInput"
                          size="large"
                          addonBefore={prefixSelector}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      lg={{ span: 12 }}
                      xl={{ span: 12 }}
                      xxl={{ span: 12 }}
                      className="contactFieldCol"
                    >
                      <label className="contactFieldLabel">City</label>
                      <Form.Item name="city">
                        <Input className="contactFieldInput" size="large" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <label className="contactFieldLabel">
                        Please give info about your requirement
                      </label>
                      <Form.Item name="moreInfo">
                        <TextArea rows={6} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      className="contactSubmitBtn"
                    >
                      Send Message
                    </Button>
                  </Row>
                </Form>
                <Divider />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <FooterComp />
    </>
  );
};

export default ContactPage;
